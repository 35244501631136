<template>
  <div>
    <button
      v-if="loggedIn === false"
      class="lg:flex lg:flex-row block w-full lg:w-auto font-bold  px-4 py-4 text-base text-center lg:text-left"
      @click="onShowLogin()"
    >
      <span>{{ $t('LOGIN') }}</span>
    </button>

    <a
      v-else
      class="lg:flex lg:flex-row block w-full lg:w-auto font-bold  px-4 py-4 text-base text-center lg:text-left"
      :href="'/account'"
      :title="$t('ACCOUNT')"
    >
      <span>{{ $t('ACCOUNT') }}</span>
    </a>
    <login
      v-if="showLogin === true"
      :show-login="showLogin"
      :redirect-after-login="true"
      @showLogin="onShowLogin()"
    />
  </div>
</template>
  
<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
const Login = defineAsyncComponent(() =>
  import("./Login" /* webpackChunkName: "login" */)
);
  
export default {
  name: "NavigationLogin",
  components: {
    Login
  },
  data() {
    return {
      loggedIn: false,
      showLogin: false
    };
  },
  mounted() {
    this.intervalid1 = setInterval(function(){
      if (typeof window.localStorage.getItem('logged-in') === 'undefined' || window.localStorage.getItem('logged-in') === null) {
        this.loggedIn =  false;
      }
      this.loggedIn = (window.localStorage.getItem('logged-in') === 'true');
    }.bind(this), 500);
    this.intervalid2 = setInterval(function(){
      const config = {
        headers: { Authorization: `Bearer ${this.$store.state.auth.token}` }
      };
      
      axios.interceptors.response.use(
        response => response,
        error => {
          const status = error.response ? error.response.status : null;
          
          if (status === 401) {
            // Handle unauthorized access
            window.localStorage.setItem('logged-in', 'false')
            window.localStorage.setItem('user-token', null)
          } else if (status === 404) {
            // Handle not found errors
            window.localStorage.setItem('logged-in', 'false')
            window.localStorage.setItem('user-token', null)
          } else {
            // Handle other errors
            console.error("An error occurred");
          }
          
          return Promise.reject(error);
        }
      );
      axios.get(process.env.apiURL + '/fe/api/account', config)
        .then(response => {
          window.localStorage.setItem('logged-in', 'true')
        })
        .finally(function () {
        });
    }.bind(this), 60000);
  },
  methods: {
    onShowLogin() {
      this.showMobileMenu = false;
      this.open = false;
      this.showLogin = !this.showLogin;
    },
  }
}
</script>